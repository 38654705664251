import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from '../../../components/layout';
export const _frontmatter = {
  "title": "Write a custom block"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Write a custom block`}</h1>
    <p>{`A Block is a component that the editor can control. It's typically a
component that has pre-made styles and accepts a few props for changing
the content.`}</p>
    <p>{`Components can expose a styling API using `}<inlineCode parentName="p">{`Controls.Style`}</inlineCode>{` which maps to the
`}<inlineCode parentName="p">{`sx`}</inlineCode>{` prop in Theme UI. There are component types you can specify like
`}<inlineCode parentName="p">{`Typography`}</inlineCode>{` and `}<inlineCode parentName="p">{`Layout`}</inlineCode>{` to constrain which props are possible.`}</p>
    <h2>{`Adding controls to a component`}</h2>
    <p>{`There are two types of controls that can be exposed. Prop controls map to
common JSX primitive prop types like strings, numbers, and enums. There are
also style properties which can be added to expose styling knobs and dials.`}</p>
    <h3>{`Prop controls`}</h3>
    <p>{`After instantiating a component, you can use `}<inlineCode parentName="p">{`Controls.addPropertyControls`}</inlineCode>{`
which will expose controls in the editor sidepanel.`}</p>
    <p>{`The following adds a string input for the quote.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`Controls.addPropertyControls(BlockQuote.Quote, {
  children: {
    type: Controls.String,
    required: true,
    placeholder: 'What did someone say?'
  },
  sx: {
    type: Controls.Style.Typography
  }
})
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "/docs/controls"
      }}>{`See Controls page `}{`→`}</a></p>
    <h3>{`Style controls`}</h3>
    <p>{`If you want to expose style controls, you can do so with `}<inlineCode parentName="p">{`Controls.addStyleControls`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`Controls.addStyleControls(BlockQuote, Controls.Style.Layout)
`}</code></pre>
    <p>{`Styles controls have particular presets that can be used, too.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`Controls.Style.Layout`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`Controls.Style.Typography`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`Controls.Style.Variant`}</inlineCode></li>
    </ul>
    <h3><inlineCode parentName="h3">{`sx`}</inlineCode>{` or `}<inlineCode parentName="h3">{`css`}</inlineCode>{` prop controls`}</h3>
    <p>{`You can combine `}<inlineCode parentName="p">{`Controls.addPropertyControls`}</inlineCode>{` with `}<inlineCode parentName="p">{`Controls.Style`}</inlineCode>{` as a shortcut to
avoid `}<inlineCode parentName="p">{`Controls.addStyleControls`}</inlineCode>{`. For Theme UI, you'd set it to `}<inlineCode parentName="p">{`sx`}</inlineCode>{`. Using Emotion
or Styled Components you can specify `}<inlineCode parentName="p">{`css`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`Controls.addPropertyControls(BlockQuote.Quote, {
  children: {
    type: Controls.String,
    required: true,
    placeholder: 'What did someone say?'
  },
  sx: {
    type: Controls.Style.Typography
  }
})
`}</code></pre>
    <h2>{`Using a block`}</h2>
    <p>{`The Blocks editor accepts a `}<inlineCode parentName="p">{`blocks`}</inlineCode>{` prop where you specify
the component, its import location, and its usage. When the
block is dragged onto the canvas, the usage code is what ends
up in the document.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react'
import { Editor } from 'blocks-ui'
import { HeaderBasic } from '@blocks/components'

export default () => <Editor blocks={{ HeaderBasic }} jsx={myJSX} />
`}</code></pre>
    <h2>{`Example block component`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from '@theme-ui/components'
import { ControlType, applyPropertyControls } from 'property-controls'

const HeaderBasic = ({ justifyContent = 'space-between', ...props }) => {
  return (
    <header
      sx={{
        variant: 'styles.header',
        display: 'flex',
        alignItems: 'center',
        justifyContent
      }}
      {...props}
    />
  )
}

HeaderBasic.Logo = props => {
  return (
    <Link
      sx={{
        variant: 'styles.navLink',
        p: 2
      }}
      {...props}
    />
  )
}
HeaderBasic.Nav = props => {
  return <nav {...props} />
}
HeaderBasic.Link = props => {
  return (
    <Link
      sx={{
        variant: 'styles.navLink',
        p: 2
      }}
      {...props}
    />
  )
}

applyPropertyControls(HeaderBasic, {
  justifyContent: {
    type: ControlType.Enum,
    defaultValue: 'right',
    options: ['space-between', 'start', 'space-evenly']
  },
  sx: {
    type: ControlType.Style
  }
})

const linkControls = {
  children: {
    title: 'Text',
    type: ControlType.String,
    required: true
  },
  to: {
    title: 'URL',
    type: ControlType.String,
    defaultValue: '#!',
    required: true
  },
  sx: {
    type: ControlType.Style
  }
}

applyPropertyControls(HeaderBasic.Logo, linkControls)
applyPropertyControls(HeaderBasic.Link, linkControls)

HeaderBasic.usage = \`
  <HeaderBasic>
    <HeaderBasic.Logo to="/">Hello</HeaderBasic.Logo>
    <HeaderBasic.Nav>
      <HeaderBasic.Link to="/about">About</HeaderBasic.Link>
      <HeaderBasic.Link to="/blog">Blog</HeaderBasic.Link>
      <HeaderBasic.Link to="/contact">Contact</HeaderBasic.Link>
    </HeaderBasic.Nav>
  </HeaderBasic>
\`

export default HeaderBasic
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      